import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery } from 'redux-saga/effects'

import { CurrencyResponse } from 'features/common/types'
import { getCurrencies } from 'slices/currency/api'
import { currencyActions } from 'slices/currency/store/currency.slice'

function* onGetCurrencies({
  payload,
}: {
  type: typeof currencyActions.getCurrencies
  payload: string | undefined
}): SagaIterator {
  yield put(currencyActions.getCurrenciesStart())
  try {
    const res: CurrencyResponse = yield call(getCurrencies, payload)
    yield put(currencyActions.getCurrenciesSuccess(res.items))
  } catch (e) {
    yield put(currencyActions.getCurrenciesFailure(e))
  }
}

// Watcher Saga
export function* currencyWatcherSaga(): SagaIterator {
  yield takeEvery(currencyActions.getCurrencies.type, onGetCurrencies)
}

export default currencyWatcherSaga
