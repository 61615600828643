import { Loadable } from '@watersport/ui-react'
import { lazy } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

import MainLayout from 'features/common/components/layouts/MainLayout'

import AppProvider from './contexts/AppProvider'
import './index.css'

const BookingPage = Loadable(lazy(() => import('pages/BookingPage')))
const FaqPage = Loadable(lazy(() => import('pages/FaqPage')))
const LandingPage = Loadable(lazy(() => import('pages/LandingPage')))
const NotFound = Loadable(lazy(() => import('pages/NotFound')))
const PrivacyPolicyPage = Loadable(lazy(() => import('pages/PrivacyPolicyPage')))
const TermsConditionsPage = Loadable(lazy(() => import('pages/TermsConditionsPage')))

const App = () => (
  <BrowserRouter>
    <AppProvider>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<LandingPage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-conditions" element={<TermsConditionsPage />} />
        </Route>
        <Route path="/404" element={<NotFound />} />
        <Route path="/:cityName" element={<BookingPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </AppProvider>
  </BrowserRouter>
)

export default App
