import { MAX_PAGE_SIZE } from 'constant'
import {
  Activity,
  ActivityResponse,
  BaseSearchParams,
  CityResponse,
  LocationResponse,
  SearchParams,
  SearchVendorResponse,
} from 'features/common/types'
import { mainAPI } from 'slices/api'

export const getActivities = (search?: string): Promise<ActivityResponse> =>
  mainAPI.get('/activity', { params: { search, page_size: MAX_PAGE_SIZE } })

export const getActivityImg = (shortName: string): Promise<{ activities: Activity[] }> =>
  mainAPI.get('/activity', { params: { short_name: shortName } })

export const getCities = (city: string): Promise<CityResponse> =>
  mainAPI.get('/city', { params: { search: city } })

export const getLocations = (params: {
  locationName?: string
  activityName: string
}): Promise<LocationResponse> =>
  mainAPI.get('/location/search', {
    params: {
      location_name: params.locationName,
      activity_name: params.activityName,
      page_size: MAX_PAGE_SIZE,
    },
  })

export const getVendors = (data: SearchParams): Promise<SearchVendorResponse> => {
  const params = {
    location_name: data.city,
    short_name: data.shortName,
    currency: data.selectedCurrencyCode,
    location_ids: data.locationIds?.join(','),
    types: data.types?.join(','),
    durations: data.durations?.join(','),
    page: data.page,
    page_size: data.pageSize,
    ne_lat: data.neLat,
    ne_lng: data.neLng,
    sw_lat: data.swLat,
    sw_lng: data.swLng,
  }
  return mainAPI.get('/vendor/search', {
    params: Object.fromEntries(Object.entries(params).filter(([, value]) => value)),
  })
}

export const getFilterCategory = (data: BaseSearchParams): Promise<SearchVendorResponse> =>
  mainAPI.get('/vendor/search/categories', {
    params: {
      location_name: data.city,
      short_name: data.shortName,
      currency: data.selectedCurrencyCode,
    },
  })

export const currencyConversion = (data: {
  base_currency_code: string
  desired_currency_code: string
}): Promise<{ conversion_rate: number }> => mainAPI.post('/currency', data)

export const requestBooking = (
  data: {
    discount_code: string
    location_id: string
    inventory_id: string
    start_time: string
    end_time: string
  },
  locale: string,
  currency: string,
): Promise<{ payment_link: string; booking_id: string }> => {
  const bookingUrl = `/booking?locale=${locale}${currency ? `&currency=${currency}` : ''}`
  return mainAPI.post(bookingUrl, data)
}
