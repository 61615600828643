import { CheckIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { Loading, useOutsideClick } from '@watersport/ui-react'
import React, { useMemo, useCallback, useState, useRef, useEffect } from 'react'

import { Currency as CurrencyType } from 'features/common/types'
import { useCurrencyService } from 'slices/currency/hooks'

export interface CurrencyProps {
  setShowCurrencyNav: React.Dispatch<React.SetStateAction<boolean>>
  show: boolean
}

const FIXED_CURRENCY_CODES = ['EUR', 'GBP', 'USD']

const Currency = ({ setShowCurrencyNav, show }: CurrencyProps) => {
  const {
    currency: { currencies, selectedCurrency },
    setSelectedCurrency,
  } = useCurrencyService()
  const [loadingCurrency, setLoadingCurrency] = useState(false)
  const [searchKey, setSearchKey] = useState('')
  const [checkedCurrency, setCheckedCurrency] = useState(selectedCurrency)
  const wrapperRef = useRef(null)

  useEffect(() => {
    setCheckedCurrency(selectedCurrency)
  }, [selectedCurrency])

  const sortedCurrencies = useMemo(
    () => currencies.filter(item => !FIXED_CURRENCY_CODES.includes(item.code)),
    [currencies],
  )

  const fixed = useMemo(() => {
    const cur: CurrencyType[] = []

    FIXED_CURRENCY_CODES.forEach(code => {
      const matched = currencies.find(item => item.code === code)
      if (matched) {
        cur.push(matched)
      }
    })
    return cur
  }, [currencies])

  const filtered = useMemo(
    () =>
      sortedCurrencies.filter(item => item.code.toLowerCase().includes(searchKey.toLowerCase())),
    [searchKey, sortedCurrencies],
  )

  const selectCurrency = useCallback((currency: CurrencyType) => {
    setCheckedCurrency(currency)
  }, [])

  const chooseCurrency = useCallback(
    async (currency: CurrencyType) => {
      setSelectedCurrency(currency)
      setLoadingCurrency(false)
      setShowCurrencyNav(false)
    },
    [setSelectedCurrency, setShowCurrencyNav],
  )

  const goBack = () => {
    setShowCurrencyNav(false)
  }

  useOutsideClick(wrapperRef, goBack)

  const currencyItem = (cur: CurrencyType, key: number) => (
    <div
      className={`w-full h-10 rounded-md border border-solid my-2.5 p-2.5 text-base flex items-center ${
        checkedCurrency?.code === cur.code
          ? 'bg-[#1AA5BD] text-white'
          : 'bg-white border-gray-400 text-gray-400'
      }`}
      onClick={() => selectCurrency(cur)}
      key={key}
    >
      <div
        className={`w-20 text-lg font-semibold ${
          checkedCurrency?.code === cur.code ? 'text-white' : 'text-black'
        }`}
      >
        {cur.code}
      </div>
      <div className="font-normal text-left text-ellipsis whitespace-nowrap overflow-hidden">
        {cur.name}
      </div>
    </div>
  )

  return (
    <div
      className={`w-full h-screen bg-slate-900 bg-opacity-50 pt-32 absolute top-0 z-50 overflow-hidden ${
        show ? 'opacity-100 translate-y-0' : 'opacity-100 translate-y-full delay-500'
      }`}
      data-testid="currency-nav"
    >
      {loadingCurrency && <Loading>{'Loading...'}</Loading>}
      <div
        ref={wrapperRef}
        className={`bg-white rounded-t-2xl h-full ${
          show
            ? 'opacity-100 translate-y-0 transition duration-500 ease-in-out'
            : 'opacity-100 translate-y-full transition duration-500 ease-in-out'
        }`}
      >
        <div className="w-full h-14 p-2.5 flex items-center justify-between">
          <div className="flex flex-row items-center">
            <div className="ml-2.5 text-2xl text-black text-center">Choose currency</div>
          </div>
          {checkedCurrency && checkedCurrency !== selectedCurrency ? (
            <CheckIcon
              className="h-8 w-8 mr-2 text-gray-700"
              onClick={() => chooseCurrency(checkedCurrency)}
            />
          ) : (
            <XMarkIcon className="h-8 w-8 mr-2 text-gray-700" onClick={goBack} />
          )}
        </div>
        <div className="h-10 bg-white rounded-md border border-solid border-neutral-300 m-2.5 p-2.5 flex items-center">
          <MagnifyingGlassIcon className="h-6 w-6 text-gray-700" />
          <input
            className="w-full h-full outline-none ml-2 text-lg font-semibold text-neutral-900 placeholder:font-normal"
            placeholder={'Search'}
            onChange={e => setSearchKey(e.target.value)}
          />
        </div>
        <div
          className="w-full h-full overflow-y-auto px-2.5 pb-28"
          data-testid="currency.currenciesCollection"
        >
          {fixed.map((cur: CurrencyType, i: number) => currencyItem(cur, i))}
          <span className="font-normal text-base text-gray-400">{'other currencies:'}</span>
          {filtered.map((cur: CurrencyType, i: number) => currencyItem(cur, i))}
        </div>
      </div>
    </div>
  )
}

export default Currency
