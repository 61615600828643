import { SagaIterator } from '@redux-saga/core'
import { call, delay, put, takeEvery } from 'redux-saga/effects'

import {
  ActivityResponse,
  BaseSearchParams,
  CityResponse,
  FilterCategory,
  SearchParams,
  SearchVendorResponse,
} from 'features/common/types'
import {
  getActivities,
  getActivityImg,
  getCities,
  getFilterCategory,
  getVendors,
} from 'slices/booking/api'
import { bookingActions } from 'slices/booking/store/booking.slice'

function* onGetActivities({
  payload,
}: {
  type: typeof bookingActions.getActivities
  payload: string | undefined
}): SagaIterator {
  try {
    const res: ActivityResponse = yield call(getActivities, payload)
    yield put(bookingActions.getActivitiesSuccess(res.items))
  } catch (e) {
    yield put(bookingActions.getActivityImgFailure(e))
  }
}

function* onGetActivityImg({
  payload,
}: {
  type: typeof bookingActions.getActivityImg
  payload: string
}): SagaIterator {
  try {
    const res: ActivityResponse = yield call(getActivityImg, payload)
    const files = res.items[0].files || []
    if (files) {
      yield put(bookingActions.getActivityImgSuccess(files))
    } else {
      yield put(bookingActions.getActivityImgFailure('No activity file fetched.'))
    }
  } catch (e) {
    yield put(bookingActions.getActivityImgFailure(e))
  }
}

function* onGetCityImg({
  payload,
}: {
  type: typeof bookingActions.getCityImg
  payload: string
}): SagaIterator {
  try {
    const res: CityResponse = yield call(getCities, payload)
    const files = res.items[0].files || []
    const file = files.length > 0 ? files[0] : undefined
    if (file) {
      yield put(bookingActions.getCityImgSuccess(file))
    } else {
      yield put(bookingActions.getCityImgFailure('No city file fetched.'))
    }
  } catch (e) {
    yield put(bookingActions.getCityImgFailure(e))
  }
}

function* onGetVendors({
  payload,
}: {
  type: typeof bookingActions.getVendors
  payload: SearchParams
}): SagaIterator {
  try {
    if (payload.page === 1) yield put(bookingActions.setLoading(true))
    const res: SearchVendorResponse = yield call(getVendors, payload)
    yield put(bookingActions.getVendorsSuccess(res))
    if (res.page === 1) yield delay(2000)
    yield put(bookingActions.setLoading(false))
  } catch (e) {
    yield put(bookingActions.getVendorsFailure(e))
    yield put(bookingActions.setLoading(false))
  }
}

function* onGetFilterCategory({
  payload,
}: {
  type: typeof bookingActions.getVendors
  payload: BaseSearchParams
}): SagaIterator {
  try {
    const res: FilterCategory = yield call(getFilterCategory, payload)
    yield put(
      bookingActions.getFilterCategorySuccess({
        ...res,
        durations: res?.durations?.filter(d => d._id?.duration),
        types: res?.types?.filter(t => t._id),
      }),
    )
  } catch (e) {
    yield put(bookingActions.getVendorsFailure(e))
  }
}

// Watcher Saga
export function* bookingWatcherSaga(): SagaIterator {
  yield takeEvery(bookingActions.getActivities.type, onGetActivities)
  yield takeEvery(bookingActions.getActivityImg.type, onGetActivityImg)
  yield takeEvery(bookingActions.getCityImg.type, onGetCityImg)
  yield takeEvery(bookingActions.getVendors.type, onGetVendors)
  yield takeEvery(bookingActions.getFilterCategory.type, onGetFilterCategory)
}

export default bookingWatcherSaga
