import React from 'react'
import { useNavigate } from 'react-router-dom'

import { BOOKING_URL } from 'constant'
import WhatsappURL from 'features/common/helpers/whatsappURL'

export interface NavProps {
  show: boolean
  toggleNavOpen: () => void
  topPadding?: number
}

const Nav = ({ toggleNavOpen, show, topPadding }: NavProps) => {
  const whatsappUrl = WhatsappURL()
  const navigate = useNavigate()

  return (
    <nav
      className={`ml-auto flex flex-col fixed bottom-0 bg-white !z-10 w-full py-2.5 ${
        show
          ? 'opacity-100 translate-x-0 transition duration-500 ease-in-out'
          : 'opacity-100 translate-x-full transition duration-500 ease-in-out'
      }`}
      style={{ top: `${topPadding}px` }}
    >
      <div className="px-2.5 border-b border-solid border-gray-400">
        <a
          href="https://w-sports.click/"
          className="mx-auto text-base text-gray-700 text-left no-underline flex-row relative inline-block align-top py-4 text-lg flex items-center"
          rel="noreferrer"
        >
          <img
            src={'/static/images/ic_home.svg'}
            loading="lazy"
            alt=""
            className="w-6 h-6 mr-2.5"
          />
          Homepage
        </a>
      </div>
      <div className="px-2.5 pb-5 border-b border-solid border-gray-400 flex flex-col">
        <a
          href="#"
          className="text-base text-gray-700 text-left no-underline flex-row relative inline-block align-top py-4 text-lg block"
          onClick={() => toggleNavOpen()}
        >
          Jet Ski
        </a>
        <a
          href="#"
          className="text-base text-gray-700 text-left no-underline flex-row relative inline-block align-top py-4 text-lg block"
          onClick={() => toggleNavOpen()}
        >
          Wakeboard
        </a>
        <a
          href="#"
          className="text-base text-gray-700 text-left no-underline flex-row relative inline-block align-top py-4 text-lg block"
          onClick={() => toggleNavOpen()}
        >
          Banana
        </a>
        <a
          href="#"
          className="text-base text-gray-700 text-left no-underline flex-row relative inline-block align-top py-4 text-lg block"
          onClick={() => toggleNavOpen()}
        >
          Paddle-Board
        </a>
        <a
          href={whatsappUrl}
          id="btn-header-search-now"
          target="_blank"
          className="h-12 bg-gray-800 rounded border-0 my-2.5 py-0 px-4 text-xl font-medium text-white text-center no-underline cursor-pointer italic flex items-center justify-center hover:bg-gray-600 mx-0 mb-0"
          rel="noreferrer"
        >
          Explore more
        </a>
      </div>
      <div className="px-2.5 flex flex-col">
        <a
          href="#"
          className="text-base text-gray-700 text-left no-underline flex-row relative inline-block align-top py-4 text-lg block"
          onClick={() => {
            window.location.href = `${BOOKING_URL}/support`
          }}
        >
          Support
        </a>
        <a
          href="#"
          className="text-base text-gray-700 text-left no-underline flex-row relative inline-block align-top py-4 text-lg block"
          onClick={() => navigate('/terms-conditions')}
        >
          Terms & Conditions
        </a>
      </div>
    </nav>
  )
}

export default Nav
