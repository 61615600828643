import { useCallback } from 'react'

import { Currency } from 'features/common/types'
import { currencyActions, CurrencyState, selectCurrency } from 'slices/currency/store'
import { useAppDispatch, useAppSelector } from 'store/hooks'

export type BookSummaryOperators = {
  currency: CurrencyState
  getCurrencies: (locationName?: string) => void
  setSelectedCurrency: (currency?: Currency) => void
}

export const useCurrencyService = (): Readonly<BookSummaryOperators> => {
  const dispatch = useAppDispatch()

  return {
    currency: useAppSelector(selectCurrency),
    getCurrencies: useCallback(
      (locationName?: string) => dispatch(currencyActions.getCurrencies(locationName)),
      [dispatch],
    ),
    setSelectedCurrency: useCallback(
      (currency?: Currency) => dispatch(currencyActions.setSelectedCurrency(currency)),
      [dispatch],
    ),
  }
}

export default useCurrencyService
