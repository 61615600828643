import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  Activity,
  BaseSearchParams,
  File,
  FilterCategory,
  MapSearchParams,
  SearchParams,
  SearchVendorResponse,
  Vendor,
} from 'features/common/types'
import type { RootState } from 'store/store'

export interface BookingState {
  loading: boolean
  vendors: Vendor[]
  filteredCount: number
  page: number
  pageSize: number
  total: number
  showFilter: boolean
  filterCategory: FilterCategory | undefined
  filters: FilterCategory | undefined
  mapBounds: MapSearchParams | undefined
  activityImg: File[]
  cityImg: File | undefined
  activities: Activity[]
  error: unknown | undefined
}

const initialState: BookingState = {
  loading: false,
  vendors: [],
  filteredCount: 0,
  page: 1,
  pageSize: 10,
  total: 0,
  showFilter: false,
  filterCategory: undefined,
  filters: undefined,
  mapBounds: undefined,
  activityImg: [],
  cityImg: undefined,
  activities: [],
  error: undefined,
}

const slice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    getActivitiesStart(state) {
      state.loading = true
    },
    getActivitiesSuccess(state, action: PayloadAction<Activity[]>) {
      state.activities = action.payload
      state.error = undefined
    },
    getActivitiesFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
      state.activities = []
    },

    getActivityImgStart(state) {
      state.loading = true
    },
    getActivityImgSuccess(state, action: PayloadAction<File[]>) {
      state.activityImg = action.payload
      state.error = undefined
    },
    getActivityImgFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
      state.activityImg = []
    },

    getCityImgStart(state) {
      state.loading = true
    },
    getCityImgSuccess(state, action: PayloadAction<File>) {
      state.cityImg = action.payload
      state.error = undefined
    },
    getCityImgFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
      state.cityImg = undefined
    },

    getVendorsStart(state) {
      state.loading = true
    },
    getVendorsSuccess(state, action: PayloadAction<SearchVendorResponse>) {
      if (action.payload.page === 1) {
        state.vendors = action.payload.items
      } else {
        state.vendors = [...state.vendors, ...action.payload.items]
      }
      state.page = action.payload.page
      state.pageSize = action.payload.page_size
      state.filteredCount = action.payload.total_items
      if (!state.filters) {
        state.total = action.payload.total_items
      }
      state.error = undefined
    },
    getVendorsFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
      state.vendors = []
    },

    getFilterCategoryStart() {},
    getFilterCategorySuccess(state, action: PayloadAction<FilterCategory>) {
      state.filterCategory = action.payload
    },
    getFilterCategoryFailure() {},

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setShowFilter(state, action: PayloadAction<boolean>) {
      state.showFilter = action.payload
    },
    setFilters(state, action: PayloadAction<FilterCategory | undefined>) {
      state.filters = action.payload
    },
    setMapBounds(state, action: PayloadAction<MapSearchParams | undefined>) {
      state.mapBounds = action.payload
    },
  },
})

export const bookingActions = {
  getActivities: createAction(`${slice.name}/getActivities`, (search?: string) => ({
    payload: search,
  })),
  getActivitiesStart: slice.actions.getActivitiesStart,
  getActivitiesSuccess: slice.actions.getActivitiesSuccess,
  getActivitiesFailure: slice.actions.getActivitiesFailure,

  getActivityImg: createAction(`${slice.name}/getActivityImg`, (shortName: string) => ({
    payload: shortName,
  })),
  getActivityImgStart: slice.actions.getActivityImgStart,
  getActivityImgSuccess: slice.actions.getActivityImgSuccess,
  getActivityImgFailure: slice.actions.getActivityImgFailure,

  getCityImg: createAction(`${slice.name}/getCityImg`, (city: string) => ({ payload: city })),
  getCityImgStart: slice.actions.getCityImgStart,
  getCityImgSuccess: slice.actions.getCityImgSuccess,
  getCityImgFailure: slice.actions.getCityImgFailure,

  getVendors: createAction(`${slice.name}/getVendors`, (data: SearchParams) => ({
    payload: data,
  })),
  getVendorsStart: slice.actions.getVendorsStart,
  getVendorsSuccess: slice.actions.getVendorsSuccess,
  getVendorsFailure: slice.actions.getVendorsFailure,

  getFilterCategory: createAction(`${slice.name}/getFilterCategory`, (data: BaseSearchParams) => ({
    payload: data,
  })),
  getFilterCategoryStart: slice.actions.getFilterCategoryStart,
  getFilterCategorySuccess: slice.actions.getFilterCategorySuccess,
  getFilterCategoryFailure: slice.actions.getFilterCategoryFailure,

  setLoading: slice.actions.setLoading,

  setShowFilter: slice.actions.setShowFilter,
  setFilters: slice.actions.setFilters,
  setMapBounds: slice.actions.setMapBounds,
}

export const selectBooking = (state: RootState) => state.booking

export default slice.reducer
