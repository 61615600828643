import { useCallback } from 'react'

import { Vendor } from 'features/common/types'
import { bookSummaryActions, BookSummaryState, selectBookSummary } from 'slices/bookSummary/store'
import { useAppDispatch, useAppSelector } from 'store/hooks'

export type BookSummaryOperators = {
  bookSummary: BookSummaryState
  setSelectDateTime: (select: boolean) => void
  setBookVendor: (vendor: Vendor | null) => void
  setShowCalendar: (show: boolean) => void
  setShowBookNow: (show: boolean) => void
  setShowMap: (show: boolean) => void
  setDate: (date: string | undefined) => void
  setTime: (time: string | undefined) => void
  setStep: (step: number) => void
  bookSummaryInit: () => void
}

export const useBookSummaryService = (): Readonly<BookSummaryOperators> => {
  const dispatch = useAppDispatch()

  return {
    bookSummary: useAppSelector(selectBookSummary),
    setSelectDateTime: useCallback(
      (select: boolean) => dispatch(bookSummaryActions.setSelectDateTime(select)),
      [dispatch],
    ),
    setBookVendor: useCallback(
      (vendor: Vendor | null) => dispatch(bookSummaryActions.setBookVendor(vendor)),
      [dispatch],
    ),
    setShowCalendar: useCallback(
      (show: boolean) => dispatch(bookSummaryActions.setShowCalendar(show)),
      [dispatch],
    ),
    setShowBookNow: useCallback(
      (show: boolean) => dispatch(bookSummaryActions.setShowBookNow(show)),
      [dispatch],
    ),
    setShowMap: useCallback(
      (show: boolean) => dispatch(bookSummaryActions.setShowMap(show)),
      [dispatch],
    ),
    setDate: useCallback(
      (date: string | undefined) => dispatch(bookSummaryActions.setDate(date)),
      [dispatch],
    ),
    setTime: useCallback(
      (time: string | undefined) => dispatch(bookSummaryActions.setTime(time)),
      [dispatch],
    ),
    setStep: useCallback((step: number) => dispatch(bookSummaryActions.setStep(step)), [dispatch]),
    bookSummaryInit: useCallback(() => dispatch(bookSummaryActions.init()), [dispatch]),
  }
}

export default useBookSummaryService
