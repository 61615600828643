import { Outlet } from 'react-router-dom'

import Footer from 'features/common/components/layouts/Footer'
import Header from 'features/common/components/layouts/Header'

const MainLayout = () => (
  <div className="flex flex-col">
    <Header />
    <Outlet />
    <Footer />
  </div>
)

export default MainLayout
