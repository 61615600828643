import axios from 'axios'

import { MAX_PAGE_SIZE } from 'constant'
import { CurrencyResponse } from 'features/common/types'
import { mainAPI } from 'slices/api'

export const getCurrencies = (location?: string): Promise<{ currencies: CurrencyResponse[] }> =>
  mainAPI.get('/currency', { params: { location_name: location, page_size: MAX_PAGE_SIZE } })

export const getCountry = async (): Promise<{ country: string }> => {
  const { data } = await axios.get('https://ipapi.co/json/')
  return data
}
